import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Button, Switch } from 'antd';
import { 
  UserOutlined, ProfileTwoTone, PhoneTwoTone, PieChartTwoTone, 
  MenuFoldOutlined, MenuUnfoldOutlined
} from '@ant-design/icons';
import { getLocalStorageInfo } from '../utils/Common';


const { Sider } = Layout;

const NavBarLayout = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [theme, setTheme] = useState('light');
  const [mode, setMode] = useState('vertical');
  const [curMenu, setCurMenu] = useState('');

  const changeMode = (checked) => setMode(checked ? 'inline' : 'vertical');
  // const changeTheme = (checked) => setTheme(checked ? 'dark' : 'light');
  const handleClick = (e) => {
    setCurMenu('');
    setCurMenu(e.key);
  };
  const toggleCollapsed = () => setCollapsed(!collapsed);

  const userInfo = getLocalStorageInfo('curUser');
  // console.log('User info ', userInfo);

  const menuAdminItems = [
    {
      key: 'userSection',
      label: 'ユーザー管理',
      icon: <UserOutlined />,
      children: [
        { key: 'userList', label: <Link to='/homeAdmin/user-manager'>ユーザー一覧 </Link> },
        // { key: 'userGroup', label: <Link to='/homeAdmin/user-group'>グループユーザー </Link> },
      ],
    },
    {
      key: 'projectSection',
      label: 'プロジェクト管理',
      icon: <ProfileTwoTone />,
      children: [
        { key: 'workgroupList', label: <Link to='/homeAdmin/work-groups'>リスト会社 </Link> },
        { key: 'projectList', label: <Link to='/homeAdmin/project-list'>プロジェクトの一覧 </Link> },
      ],
    },
    {
      key: 'callCenterSection',
      label: 'コールセンター',
      icon: <PhoneTwoTone />,
      children: [
        { key: 'callList', label: <Link to='/homeAdmin/call-meeting'>通話リスト</Link> },
        { key: 'automatic-distribution', label: <Link to='/homeAdmin/automatic-distribution'>自動配布コールモード</Link> },
        // { key: 'candidateList', label: <Link to='/homeAdmin/candidate-list'>候補者リスト</Link> },
        { key: 'callLogs', label: <Link to='/homeAdmin/call-logs'>コールログ </Link> },
        // { key: 'callRecording', label: <Link to='/homeAdmin/call-recording'>通話録音 </Link> },
        // { key: 'callTwilio', label: <Link to='/homeAdmin/call-twilio'>Twilio 通話 </Link> },
      ],
    },
    {
      key: 'callReportSection',
      label: '通話レポート',
      icon: <PieChartTwoTone />,
      children: [
        { key: 'zoomCallReport', label: <Link to='/homeAdmin/call-report-zoom'>レポート</Link> },
        // { key: 'dataFromGGS', label: <Link to='/homeAdmin/gss-list'>GSSからのデータ</Link> },
        // { key: 'dataToGGS', label: <Link to='/homeAdmin/gss-sync'>GGSにデータを同期する</Link> },
      ],
    },
  ];

  const menuUserItems = [
    {
      key: 'callCenterSection',
      label: 'Call Center',
      icon: <PhoneTwoTone />,
      children: [
        { key: 'callList', label: <Link to='/homeAdmin/call-meeting'>通話リスト </Link> },
        { key: 'automatic-distribution', label: <Link to='/homeAdmin/automatic-distribution'>自動配布コールモード </Link> },
        { key: 'callLogs', label: <Link to='/homeAdmin/call-logs'>コールログ </Link> },
        // { key: 'callRecording', label: <Link to='/homeAdmin/call-recording'>通話録音 </Link> },
      ],
    },
    {
      key: 'callReportSection',
      label: '通話レポート',
      icon: <PieChartTwoTone />,
      children: [
        { key: 'zoomCallReport', label: <Link to='/homeAdmin/call-report-zoom'>レポート</Link> },
        // { key: 'dataFromGGS', label: <Link to='/homeAdmin/gss-list'>GSSからのデータ</Link> },
        // { key: 'dataToGGS', label: <Link to='/homeAdmin/gss-sync'>GGSにデータを同期する</Link> },
      ],
    },

  ];

  const menuItems = (userInfo?.is_admin) ? menuAdminItems :  menuUserItems;


  return (
    <Sider 
      width={260} 
      theme={theme} 
      className="site-layout-background" 
      collapsed={collapsed} 
      onCollapse={setCollapsed}
    >
      <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {/* <Switch
          checked={theme === 'dark'}
          onChange={changeTheme}
          checkedChildren="Dark"
          unCheckedChildren="Light"
        /> */}
        <Switch 
          checkedChildren="Vertical" 
          unCheckedChildren="Inline" 
          onChange={changeMode} 
        />
        <Button
          type="primary"
          onClick={toggleCollapsed}
          style={{ marginBottom: 16 }}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
      <Menu
        selectedKeys={[curMenu]}
        // defaultSelectedKeys={['callCenterSection']}
        // defaultOpenKeys={['callCenterSection']}
        mode={mode}
        theme={theme}
        //inlineCollapsed={collapsed}
        items={menuItems}
        onClick={handleClick}
      />
    </Sider>
  );
};

export default NavBarLayout;
