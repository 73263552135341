import React, { useState, useEffect, useMemo, useContext } from "react";
import { Table, Col, Row, Layout, Tabs, Modal, Button, List, Space, Spin } from "antd";

import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import PhoneHeader from "./PhoneHeader";
import PhoneDetail from "./PhoneDetail";
import PhoneCall from "./PhoneCall";
import ClientSection from "./ClientSection";
import CandidateLogsList from "./CandidateLogsList";
import CallHistory from "./CallHistory";
import ReCall from "./ReCall";
import { UserContext } from "./UserContext";
import { getLocalStorageInfo, setLocalStorageInfo } from "../../utils/Common";
import { getCallData, postCallData } from "../../services/instanceCallService";
import { getManagementData } from "../../services/instanceManagementService";
import { useLocation } from "react-router-dom";

const { Content } = Layout;

const CallMeeting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wgProjectData, setWgProjectData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedKey, setSelectedKey] = useState(0);
  const targetProject = getLocalStorageInfo("callProjectId");
  const targetProjectTitle = getLocalStorageInfo("callCompanyTitle");
  const { userInfo, setUser, author, authorId } = useContext(UserContext);
  const [listUser, setListUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [sysUserData, setSysUserData] = useState([]);
  const [dataCount, setDataCount] = useState(["Not yet select"]);
  const author_id = author?.id || authorId;

  let location = useLocation();
  let dataProject = [];

  useEffect(() => {
    setUser(null);
    setLocalStorageInfo("callCompanyTitle", "");
  }, [location]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data = await getManagementData("/work_groups");
      setWgProjectData(data);
    } catch (error) {
      console.error("Error fetching project data:", error);
      setWgProjectData([]);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const showModal = () => {
    setModalTitle("ログリストを取得する会社を選択します。");
    if (wgProjectData.length === 0) {
      fetchData();
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
      showModal();
  }, [targetProject]);

  useEffect(() => {
    setUser(null);
  },[]);
  
  if (wgProjectData.data) {
    wgProjectData.data.forEach((value) => {
      if (value.projects.length > 0) {
        dataProject.push({
          id: value.id,
          title: value.name,
          workGroup: value.id,
        });
      }
    });
  }

  const listData = useMemo(() => {
    if (listUser) {
      return listUser.map((item) => ({
        ID: item.candidate_id,
        Name: item.candidate_name,
        furigana: item.furigana,
        email: item.email,
        phone: item.candidate_phone,
        address: item.address,
        birthday: item.birthday,
        gender: item.gender,
        postal_code: item.postal_code,
        callee_number: item.callee_number,
        school_type: item.school_type,
        university_name: item.university_name,
        educational_stream: item.educational_stream,
        department: item.department,
        graduation_year: item.graduation_year,
        work_year: item.work_year,
        desired_industry: item.desired_industry,
        total_call: item.total_call,
        last_call: item.last_call,
        result_call: item.result,
        author_id: item.author_id,
        receipt_id: item.receipt_id,
        status: item.status,
        count_recall: item.count_recall,
        comments: item.comments,
        candidateLogId: item.call_record_id,
        agreed_at: item.agreed_at,
      }));
    }
    return [];
  }, [listUser]);

  const handleNext = () => {
    // check if selectedKey is none or not in the list data then return nothing
    if (selectedKey === null || selectedKey === undefined || selectedKey === 0) {
      return;
    }
    listData.forEach((value, index) => {
      if (value.ID === userInfo.ID) {
        // get the next record
        if (index < listData.length - 1) {
          const newRecord = listData[index + 1];
          setUser(newRecord);
          setSelectedKey(newRecord.ID);
          // check if the next record is on the next page
          if (listData.indexOf(newRecord) >= currentPage * itemsPerPage) {
            setCurrentPage((prevPage) => prevPage + 1);
          }
        } else {
          return;
        }
      }
    });
  };

  const handlePrevious = () => {
    // check if selectedKey is none or not in the list data then return nothing
    if (selectedKey === null || selectedKey === undefined || selectedKey === 0) {
      return;
    }
    listData.forEach((value, index) => {
      if (value.ID === userInfo.ID) {
        // gett the previous record
        if (index > 0) {
          const newRecord = listData[index - 1];
          setUser(newRecord);
          setSelectedKey(newRecord.ID);
          // check if the previous record is on the previous page
          if (listData.indexOf(newRecord) < (currentPage - 1) * itemsPerPage) {
            setCurrentPage((prevPage) => prevPage - 1);
          }
        } else {
          return;
        }
      }
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedKey((page - 1) * itemsPerPage);
  };

  const handleRowSelect = (index, record) => {
    setUser(record);
    setSelectedKey(index);
  };

  
  const fetchStatusCount = async () => {
    const result = await getCallData(`/call-log/count?user_id=${author_id}`);
    setDataCount(result.data);
  };
  
  useEffect(() => {
    fetchStatusCount();
    setLocalStorageInfo("selected-receipt", "");
    setLocalStorageInfo("selected-status", "");
  }, [author_id]);
 
  const tabItems = [
    {
      key: "tabCallReport",
      label: "ヒストリー ",
      children: (
        <Row key={"callHis"} gutter={[8, 16]}>
          <Col span={24}>
            <CallHistory />
          </Col>
        </Row>
      ),
    },
    {
      key: "recall",
      label: "再コール ",
      children: (
        <Row key={"callHis"} gutter={[8, 16]}>
          <Col span={24}>
            <ReCall />
          </Col>
        </Row>
      ),
    },
  ];

  const getProjectList = async (pId, companyName) => {
    setUser(null);
    setIsLoading(true);
    setLocalStorageInfo("callCompanyTitle", JSON.stringify(companyName));
    try {
      const res = await getCallData(
        `/call-log/candidates?project_id=${pId}`
      );
      setListUser(res.data);
      setIsLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      setIsModalOpen(false);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const setPId = (pId, companyName) => {
    getProjectList(pId, companyName);
  };

  return (
    <Layout>
      <HeaderLayout />
      <Layout>
        <NavBarLayout />
        <Content style={{ padding: "10px" }}>
          <div style={{ minHeight: 760 }}>
            {(
              <>
                <h1>通常コールモード </h1>
                <Space>
                  <Button
                    type="primary"
                    onClick={showModal}
                    style={{ marginBottom: 10 }}
                  >
                    会社を選択
                  </Button>
                  <span>
                    <b>現在の会社 :</b>{" "}
                    <b style={{ color: "hotpink" }}>{targetProjectTitle}</b>
                  </span>
                </Space>
                <Row gutter={[8, 16]}>
                  <Col span="15" key="colLeft">
                    <Row gutter={[8, 16]}>
                      <Col span={24}>
                        <PhoneHeader />
                      </Col>
                      <Col span={24}>
                        <PhoneDetail />
                      </Col>
                      <Col span={24}>
                        <CandidateLogsList
                          selectedKey={selectedKey}
                          listData={listData}
                          handleRowSelect={handleRowSelect}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          itemsPerPage={itemsPerPage}
                          handlePageChange={handlePageChange}
                          startIndex={startIndex}
                          endIndex={endIndex}
                          handleNext={handleNext}
                          handlePrevious={handlePrevious}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span="9" key="colRight">
                    <Row gutter={[8, 16]}>
                      <Col span={24}>
                        <div
                          style={{
                            padding: "0",
                            height: "140px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <PhoneCall
                            handleNext={handleNext}
                            handlePrevious={handlePrevious}
                            dataCount={dataCount}
                          />
                        </div>
                      </Col>
                      <Col span={24} style={{ paddingRight: "1px !important" }}>
                        <ClientSection user2Child={userList} />
                      </Col>
                      <Col span={24} style={{ marginTop: "5px" }}>
                        <div
                          style={{
                            padding: "5px",
                            height: "420px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <Tabs
                            defaultActiveKey="tabCallReport"
                            items={tabItems}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Modal 
                  title={modalTitle}
                  open={isModalOpen}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <div 
                    id="scrollableDiv" style={{
                      height: 300,
                      overflow: "auto",
                      padding: "0 16px",
                      border: "0px solid rgba(140, 140, 140, 0.35)",
                    }}
                  >
                    <List loading={isLoading} 
                      itemLayout="horizontal"
                      dataSource={dataProject}
                      renderItem={(item, index) => (
                        <List.Item 
                          onClick={async () => {
                            setIsLoading(true);
                            setListUser([]);
                            setUser([]);
                            const result = await getManagementData(
                              `/work_groups/${item.id}/projects`
                            );
                            const pData = result.data;
                    
                            const pId = pData.map((project) => project.id);
                    
                            setPId(pId, item.title);
                          }}
                        >
                          <List.Item.Meta
                            title={<a href="#">{item.title}</a>}
                            description={
                              <span>IDを持つ会社 : {item.workGroup}</span>
                            }
                            key={index}
                          />
                        </List.Item>
                      )}
                    />
                  </div>
                </Modal>
              </>
            )}
          </div>
          <FooterLayout />
        </Content>
      </Layout>
    </Layout>
  );
};

export default CallMeeting;
