import React, { useContext, useEffect, useMemo, useState } from "react";
import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import { Content } from "antd/es/layout/layout";
import FooterLayout from "../../layout/Footer";
import {
  Button,
  Col,
  Layout,
  List,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Tabs,
} from "antd";
import PhoneHeader from "./PhoneHeader";
import PhoneDetail from "./PhoneDetail";
import "../../css/automatic.css";
import {
  DownloadOutlined,
  LeftSquareTwoTone,
  PhoneTwoTone,
  RightSquareTwoTone,
} from "@ant-design/icons";
import {
  getCurDate,
  getCurMonth,
  getLocalStorageInfo,
  setLocalStorageInfo,
} from "../../utils/Common";
import CallHistory from "./CallHistory";
import PhoneKeyboard from "./PhoneKeyboard";
import ReCall from "./ReCall";
import CandidateLogsList from "./CandidateLogsList";
import { UserContext } from "./UserContext";
import { getDataUser } from "../../services/instanceUserService";
import { getManagementData } from "../../services/instanceManagementService";
import { getCallData } from "../../services/instanceCallService";
import TypeModal from "./TypeModal";
import { useLocation } from "react-router-dom";

const AutomaticDistribution = () => {
  const [selectedKey, setSelectedKey] = useState(0);
  const [listUser, setListUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { userInfo, setUser, author, authorId } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [wgProjectData, setWgProjectData] = useState([]);
  const targetProjectTitle = getLocalStorageInfo("callCompanyTitle");
  const [isModalType, setIsModalType] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [projectId, setProjectId] = useState([]);
  const author_id = author?.id || authorId;
  const [dataCount, setDataCount] = useState(["Not yet select"]);

  const itemsPerPage = 7;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  let location = useLocation();

  useEffect(() => {
    setUser(null);
    setLocalStorageInfo("callCompanyTitle", "");
  }, [location]);

  
  useEffect(() => {
    setUser(null);
  },[]);

  const tabItems = [
    {
      key: "tabCallReport",
      label: "ヒストリー ",
      children: (
        <Row key={"callHis"}>
          <Col span={24}>
            <CallHistory />
          </Col>
        </Row>
      ),
    },
    {
      key: "recall",
      label: "再コール ",
      children: (
        <Row key={"callHis"}>
          <Col span={24}>
            <ReCall />
          </Col>
        </Row>
      ),
    },
  ];

  const handleChooseCompany = () => {
    setModalTitle("ログリストを取得する会社を選択します。");
    if (wgProjectData.length === 0) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const data = await getManagementData("/work_groups");
          setWgProjectData(data);
        } catch (error) {
          console.error("Error fetching project data:", error);
          setWgProjectData([]);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const result = await getCallData(
          `/call-log/pick?type=data${
            projectId.length > 0 ? `&project_id=${projectId}` : ""
          }`
        );
        setListUser(result.data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setIsLoading(false);
        setIsModalOpen(false);
      } finally {
        setIsLoading(false);
        setIsModalOpen(false);
      }
    };
    fetchData();
    if (!targetProjectTitle) {
      handleChooseCompany();
    }
  }, [projectId]);


  
  useEffect(() => {
    const fetchStatusCount = async () => {
      const result = await getCallData(`/call-log/count?user_id=${author_id}`);
      setDataCount(result.data);
    };
    fetchStatusCount();
    setLocalStorageInfo("selected-receipt", "");
    setLocalStorageInfo("selected-status", "");
  }, [author_id]);

  const handleOpenTypeModal = () => {
    setIsModalType(true);
  };

  const handleCloseTypeModal = () => {
    setIsModalType(false);
  };

  const listData = useMemo(() => {
    if (listUser) {
      return listUser.map((item) => ({
        ID: item.candidate_id,
        Name: item.candidate_name,
        furigana: item.furigana,
        email: item.email,
        phone: item.candidate_phone,
        address: item.address,
        birthday: item.birthday,
        gender: item.gender,
        postal_code: item.postal_code,
        callee_number: item.callee_number,
        school_type: item.school_type,
        university_name: item.university_name,
        educational_stream: item.educational_stream,
        department: item.department,
        graduation_year: item.graduation_year,
        work_year: item.work_year,
        desired_industry: item.desired_industry,
        total_call: item.total_call,
        last_call: item.last_call,
        result_call: item.result,
        author_id: item.author_id,
        receipt_id: item.receipt_id,
        status: item.status,
        count_recall: item.count_recall,
        comments: item.comments,
        candidateLogId: item.call_record_id,
        agreed_at: item.agreed_at,
      }));
    }
    return [];
  }, [listUser]);

  const handleRowSelect = (index, record) => {
    setUser(record);
    setSelectedKey(index);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedKey((page - 1) * itemsPerPage);
  };

  const handleNext = () => {
    // check if selectedKey is none or not in the list data then return nothing
    if (selectedKey === null || selectedKey === undefined || selectedKey === 0) {
      return;
    }
    listData.forEach((value, index) => {
      if (value.ID === userInfo.ID) {
        // get the next record
        if (index < listData.length - 1) {
          const newRecord = listData[index + 1];
          setUser(newRecord);
          setSelectedKey(newRecord.ID);
          // check if the next record is on the next page
          if (listData.indexOf(newRecord) >= currentPage * itemsPerPage) {
            setCurrentPage((prevPage) => prevPage + 1);
          }
        } else {
          return;
        }
      }
    });
  };

  const handlePrevious = () => {
    // check if selectedKey is none or not in the list data then return nothing
    if (selectedKey === null || selectedKey === undefined || selectedKey === 0) {
      return;
    }
    listData.forEach((value, index) => {
      if (value.ID === userInfo.ID) {
        // gett the previous record
        if (index > 0) {
          const newRecord = listData[index - 1];
          setUser(newRecord);
          setSelectedKey(newRecord.ID);
          // check if the previous record is on the previous page
          if (listData.indexOf(newRecord) < (currentPage - 1) * itemsPerPage) {
            setCurrentPage((prevPage) => prevPage - 1);
          }
        } else {
          return;
        }
      }
    });
  };

  const handleCall = () => {
    const user = listData[selectedKey];
    const callUrl = `zoomphonecall://${user.phone}`;
    window.open(callUrl);
  };

  let dataProject = [];

  if (wgProjectData.data) {
    wgProjectData.data.forEach((value) => {
      if (value.projects.length > 0) {
        dataProject.push({
          id: value.id,
          title: value.name,
          workGroup: value.id,
        });
      }
    });
  }

  const setPId = (pId, companyName) => {
    setLocalStorageInfo("callCompanyTitle", JSON.stringify(companyName));
    setLocalStorageInfo("callCompanyId", JSON.stringify(pId));
    const getProjectList = async () => {
      setUser(null);
      setIsLoading(true);
      try {
        const result = await getManagementData(
          `/work_groups/${pId}/projects`
        );

        const pData = result.data;

        const projectArray = pData.map((project) => project.id);

        setProjectId(projectArray);
      } catch (error) {
        setIsLoading(false);
        setIsModalOpen(false);
      }
    };
    getProjectList();
  };

  return (
    <Layout>
      {contextHolder}
      <HeaderLayout />
      <Layout>
        <NavBarLayout />
        <Content style={{ padding: "10px" }}>
          <div style={{ minHeight: 760 }}>
            {(
              <>
                <h1>自動配布コールモード</h1>
                <Space>
                  <Button
                    type="primary"
                    style={{ marginBottom: 10 }}
                    onClick={handleChooseCompany}
                  >
                    会社を選択
                  </Button>
                  <span>
                    <b>現在の会社 :</b>{" "}
                    <b style={{ color: "hotpink" }}>{targetProjectTitle}</b>
                  </span>
                </Space>
                <Row gutter={[8, 16]}>
                  <Col span="15" key="colLeft">
                    <Row gutter={[8, 16]}>
                      <Col span={24}>
                        <PhoneHeader />
                      </Col>
                      <Col span={24}>
                        <PhoneDetail />
                      </Col>
                      <Col span={24}>
                        <CandidateLogsList
                          listUser={listUser}
                          selectedKey={selectedKey}
                          setSelectedKey={setSelectedKey}
                          listData={listData}
                          handleRowSelect={handleRowSelect}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          startIndex={startIndex}
                          endIndex={endIndex}
                          itemsPerPage={itemsPerPage}
                          handlePageChange={handlePageChange}
                          handleNext={handleNext}
                          handlePrevious={handlePrevious}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span="9" key="colRight">
                    <Row gutter={[8, 16]}>
                      <Col span={24}>
                        <div
                          style={{
                            padding: "0",
                            height: "140px",
                            backgroundColor: "#fff",
                            display: "grid",
                            gridTemplateColumns: "1fr 2fr",
                            gap: "20px",
                            overflow: "hidden",
                          }}
                        >
                          <div className="flex flex-wrap w-250 h-full">
                            <Button
                              style={{
                                width: "250px",
                                height: "39px",
                                backgroundColor: "orange",
                                color: "#fff",
                              }}
                              onClick={handleOpenTypeModal}
                            >
                              <DownloadOutlined style={{ fontSize: "20px" }} />
                            </Button>
                            <div className="w-full flex justify-content-center">
                              <PhoneTwoTone
                                style={{ fontSize: "55px", cursor: "pointer" }}
                                twoToneColor="#52c41a"
                                onClick={handleCall}
                              />
                            </div>
                            <div className="w-full flex justify-content-center">
                              <Button type="link" onClick={handlePrevious}>
                                <LeftSquareTwoTone
                                  style={{
                                    fontSize: "30px",
                                  }}
                                />
                              </Button>
                              <Button type="link" onClick={handleNext}>
                                <RightSquareTwoTone
                                  style={{
                                    fontSize: "30px",
                                  }}
                                />
                              </Button>
                            </div>
                          </div>
                          <div className="flex flex-column gap-3">
                            <div className="text-20 bg-blue text-center text-white w-full h-50px line-height-50 fw-bold">
                              {getCurMonth()}月{getCurDate()}日
                            </div>
                            <div className="w-full h-50px bg-purple text-20 text-center flex">
                              <div className="bg-lightPurple w-50 flex flex-column justify-content-center items-center text-white fs-6">
                                <div><label className="stat-number"><b>{dataCount?.hitPerDay || "0"}</b></label></div>
                                <div>コール</div>
                              </div>
                              <div className="bg-boldPurple w-50 flex flex-column justify-content-center items-center text-white fs-6">
                                <div><label className="stat-number"><b>{dataCount?.callPerStatus || "0"}</b></label></div>
                                <div>アポ</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div
                          style={{
                            padding: "5px",
                            height: "420px",
                            backgroundColor: "#fff",
                          }}
                        >
                          <PhoneKeyboard />
                        </div>
                      </Col>
                      <Col span={24}>
                        <Tabs
                          defaultActiveKey="tabCallReport"
                          items={tabItems}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Modal
                  title={modalTitle}
                  open={isModalOpen}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <div
                    id="scrollableDiv"
                    style={{
                      height: 300,
                      overflow: "auto",
                      padding: "0 16px",
                      border: "0px solid rgba(140, 140, 140, 0.35)",
                    }}
                  >
                    <List loading={isLoading} 
                      itemLayout="horizontal"
                      dataSource={dataProject}
                      renderItem={(item, index) => (
                        <List.Item
                          onClick={() => {
                            setListUser([]);
                            setPId(item.id, item.title);
                          }}
                        >
                          <List.Item.Meta
                            title={
                              <a href="#" onClick={(e) => e.preventDefault()}>
                                {item.title}
                              </a>
                            }
                            description={
                              <span>IDを持つ会社 : {item.workGroup}</span>
                            }
                            key={index}
                          />
                        </List.Item>
                      )}
                    />
                  </div>
                </Modal>
                <TypeModal
                  title="ステータスを選択してください"
                  isModalType={isModalType}
                  handleCloseTypeModal={handleCloseTypeModal}
                  setIsSubmit={setIsSubmit}
                  messageApi={messageApi}
                  project_id={projectId}
                  setListUser={setListUser}
                />
              </>
            )}
          </div>
          <FooterLayout />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AutomaticDistribution;
