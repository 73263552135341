import global_const from "../constants/global_const";
import axios from "axios";
import { getCookies } from "../utils/getCookies";
import { logoutUser } from '../utils/Common';


const instanceCallService = axios.create({
  baseURL: global_const.CALL_SERVICE_URL,
  headers: {
    "Content-type": "application/json",
  },
});

instanceCallService.interceptors.request.use(
  (config) => {
    const curToken = getCookies();
    config.headers.Authorization = `Bearer ${curToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
instanceCallService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error("Unauthorized request - logging out");
      logoutUser(); // Call your logout function if a 401 error occurs
    }
    return Promise.reject(error);
  }
);

export const getCallData = async (endpoint) => {
  try {
    const response = await instanceCallService.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};

export const postCallData = async (endpoint, data) => {
  try {
    const response = await instanceCallService.post(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("POST request error:", error);
    throw error;
  }
};

export const putCallData = async (endpoint, data) => {
  try {
    const response = await instanceCallService.put(endpoint, data);
    return response.data;
  } catch (error) {
    console.error("PUT request error:", error);
    throw error;
  }
};

export const deleteCallData = async (endpoint) => {
  try {
    const response = await instanceCallService.delete(endpoint);
    return response.data;
  } catch (error) {
    console.error("DELETE request error:", error);
    throw error;
  }
};

export const downloadCallData = async (endpoint) => {
  try {
    const response = await instanceCallService.get(endpoint, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("GET request error:", error);
    throw error;
  }
};
