import React, { useState, useEffect, useCallback } from 'react';
import { Space, Table, Tag, Button, Modal, Form, Input, Select, message, Layout, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useCookies } from 'react-cookie';

import HeaderLayout from "../../layout/Header";
import NavBarLayout from "../../layout/NavBar";
import FooterLayout from "../../layout/Footer";
import { getFullDate, getLocalToken } from '../../utils/Common';
import globalConst from '../../constants/global_const';

const { Option } = Select;
const { confirm } = Modal;

function Workgroups() {
	const [cookies] = useCookies(['call_token']);
    const curToken = cookies.call_token ? cookies.call_token : getLocalToken();
	const [isLoading, setIsLoading] = useState(false);
	const [isWaiting, setIsWaiting] = useState(false);
	const [modalTitle, setModalTitle] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [wgData, setWgData] = useState([]);
	const [form] = Form.useForm();

	const [messageApi, contextHolder] = message.useMessage();

	const successMessage = (content) => {
		messageApi.open({
			type: 'success',
			content,
			className: 'custom-class',
			style: { marginTop: '20vh' },
		});
	};

	const showModal = () => {
		setModalTitle('新しい会社を追加する');
		form.resetFields();
		form.setFieldsValue({ userId: -1 });
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const fetchWorkgroups = useCallback(async () => {
		setIsLoading(true);
		try {
			const result = await axios.get(`${globalConst.MANAGEMENT_URL}/work_groups`, {
				credentials: 'include',
				mode: 'cors',
				headers: {
					'Content-type': 'application/json',
					'Authorization': `Bearer ${curToken}`,
				},
	
			});
			setWgData(result.data.data);
		} catch (error) {
			console.log('An error occurred while retrieving the company', error);
		} finally {
			setIsLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchWorkgroups();
	}, []);

	const onFinish = async (values) => {
		setIsWaiting(true);
		if (values.userId === -1) {
			await addNewWorkgroup(values);
		} else {
			await editWorkgroup(values);
		}
		setIsModalOpen(false);
		setIsWaiting(false);
	};

	const addNewWorkgroup = async (params) => {
		try {
			const result = await axios.post(`${globalConst.MANAGEMENT_URL}/work_groups`, params, {
				credentials: 'include',
				mode: 'cors',
				headers: {
					'Content-type': 'application/json',
					'Authorization': `Bearer ${curToken}`,
				},
	
			});
			if (result.data.success) {
				successMessage(`新しい会社が追加されました `);
				fetchWorkgroups();
			} else {
				message.error('新しい会社を追加できませんでした');
			}
		} catch (error) {
			console.error('新しい会社の追加中にエラーが発生しました', error);
		}
	};

	const editWorkgroup = async (params) => {
		try {
			await axios.put(`${globalConst.MANAGEMENT_URL}/work_groups/${params.userId}`, params, {
				credentials: 'include',
				mode: 'cors',
				headers: {
					'Content-type': 'application/json',
					'Authorization': `Bearer ${curToken}`,
				},
	
			});
			successMessage('会社は正常に更新されました');
			fetchWorkgroups();
		} catch (error) {
			console.error('会社の編集中にエラーが発生しました', error);
		}
	};

	const fetchDetailUser = async (id) => {
		setIsWaiting(true);
		try {
			const result = await axios.get(`${globalConst.MANAGEMENT_URL}/work_groups/${id}`, {
				credentials: 'include',
				mode: 'cors',
				headers: {
					'Content-type': 'application/json',
					'Authorization': `Bearer ${curToken}`,
				},
			});
			const wgItem = result.data.data;
			// console.log('deit data ', wgItem);
			form.setFieldsValue({
				name: wgItem.name,
				created_at: wgItem.created_at,
				status: wgItem.status ? '1' : '0',
				userId: wgItem.id,
			});
			setIsModalOpen(true);
		} catch (error) {
			console.error('会社の詳細を取得中にエラーが発生しました', error);
		} finally {
			setIsWaiting(false);
		}
	};

	const edit = (id) => {
		setModalTitle('会社情報を更新する');
		fetchDetailUser(id);
	};

	const showDeleteConfirm = (id) => {
		confirm({
			title: `このプロジェクトを削除しますか?`,
			icon: <ExclamationCircleOutlined />,
			content: `ID 会社を削除する ${id}`,
			okText: 'Yes',
			okType: 'danger',
			cancelText: 'No',
			async onOk() {
				try {
					const result = await axios.delete(`${globalConst.MANAGEMENT_URL}/work_groups/${id}`, {
						credentials: 'include',
						mode: 'cors',
						headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${curToken}` },
					});
					if (result.status === 204) {
						successMessage('会社は正常に削除されました。');
						fetchWorkgroups();
					} else {
						message.error('会社の削除に失敗しました');
					}
				} catch (error) {
					console.error('会社の削除中にエラーが発生しました:', error);
				}
			},
		});
	};

	const columns = [
		// { title: 'ID', dataIndex: 'id', key: 'id' },
		{ title: '名前', dataIndex: 'name', key: 'name' },
		{
			title: 'ステータス',
			dataIndex: 'status',
			key: 'status',
			render: (status) => (
				<Tag color={status ? 'volcano' : 'geekblue'}>
					{status ? 'Active' : 'Inactive'}
				</Tag>
			),
		},
		{
			title: '作成日',
			key: 'created_at',
			dataIndex: 'created_at',
			render: (date) => getFullDate(date),
		},
		{
			title: 'アクション',
			render: (_, record) => (
				<Space size="middle">
					<Button onClick={() => edit(record.id)}>編集</Button>
					<Button onClick={() => showDeleteConfirm(record.id)} danger>削除</Button>
				</Space>
			),
		},
	];

	return (
		<Layout>
			<HeaderLayout />
			<Layout>
				<NavBarLayout />
				<Layout style={{ padding: '0 24px 24px' }}>
					{contextHolder}
					{isLoading ? (
						<div
							style={{
								height: "100vh",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								backgroundColor: "transparent",
							}}
						>
							<Spin size="large" />
							<div className="ms-3 mt-2">読み込み中</div>{" "}
						</div>
					) : (
						<div style={{ minHeight: 780 }}>
							<h1>リスト会社</h1>
							<Button type="primary" onClick={showModal}>
							新しい会社を追加する
							</Button>
							<Table columns={columns} dataSource={wgData} rowKey="id" pagination={{ position: ['bottomRight'] }} />
							<Modal title={modalTitle} open={isModalOpen} onCancel={handleCancel} footer={null}>
								<Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
									<Form.Item name="name" label="Name" rules={[{ required: true }]}>
										<Input />
									</Form.Item>
									<Form.Item name="status" label="Active" rules={[{ required: true }]}>
										<Select placeholder="Select status" allowClear>
											<Option value="1">Active</Option>
											<Option value="0">Inactive</Option>
										</Select>
									</Form.Item>
									<Form.Item name="userId" hidden>
										<Input />
									</Form.Item>
									<Form.Item {...tailLayout}>
										<Button type="primary" htmlType="submit" loading={isWaiting}>
										提出する
										</Button>&nbsp;  &nbsp;
										<Button htmlType="button" onClick={() => handleCancel()}>
										リセット
										</Button>
									</Form.Item>
								</Form>
							</Modal>
						</div>
					)}
					<FooterLayout />
				</Layout>
			</Layout>
		</Layout>
	);
}

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
};

const tailLayout = {
	wrapperCol: { offset: 8, span: 16 },
};

export default Workgroups;
